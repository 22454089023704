<template>
  <div
    style="
      padding: 30px;
      align-items: center;
      box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;
      width: 100%;
      height: 100%;
    "
  >
    <!-- Counter Widgets -->
    <a-row :gutter="24">
      <a-col
        :span="24"
        :md="12"
        :xl="6"
        class="mb-24"
        v-for="(stat, index) in stats"
        :key="index"
      >
        <!-- Widget 1 Card -->
        <WidgetCounter
          :value="stat.value"
          :image="stat.image"
          :link="stat.link"
        ></WidgetCounter>
        <!-- / Widget 1 Card -->
      </a-col>
    </a-row>
    <!-- / Counter Widgets -->
  </div>
</template>

<script>
// Counter Widgets
import WidgetCounter from "../components/Widgets/WidgetCounter.vue";

// Information card 1.
import CardInfo from "../components/Cards/CardInfo";

// Information card 2.
import CardInfo2 from "../components/Cards/CardInfo2";

export default {
  components: {
    WidgetCounter,
    CardInfo,
    CardInfo2,
  },
  data() {
    return {};
  },
  computed: {
    currentUser() {
      return this.$store.getters['auth/getLogIn'];
    },

    stats() {
      return [
        {
          value: this.$t("dashboard.buyInsurance"),
          image: "images/buy-insurance.svg",
          link: "buy",
        },
        {
          value: this.$t("dashboard.myInsurance"),
          image: "images/my-insurance-laospt.svg",
          link: "my-insurance",
        },
        {
          value: this.$t("dashboard.claimRequest"),
          image: "images/claim-insurance-laospt.svg",
          link: "claim",
        },
        {
          value: this.$t("dashboard.travel"),
          image: "images/tour.svg",
          link: "travel-guide",
        },
      ];
    },
  },
  // if there is no logged in will redirect to login page
  mounted() {
    // console.log("currentUser:",this.currentUser)
    // if (!this.currentUser) {
    //   this.$router.push("/");
    // }
  },
};
</script>

<style lang="scss"></style>
