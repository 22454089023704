<template>
  <router-link v-bind:to="link">
    <a-card hoverable class="widget">
      <a slot="extra" :href="link">
        <!-- <div class="icon" v-html="icon"></div> -->
        <div>
          <img
            style="
              width: 70px;
              position: absolute;
              top: 0px;
              bottom: 0;
              left: 16px;
              padding: 5px;
              margin: 5px 0 0px 0;
              /* background-color: #fff; */
              /* border-radius: 40px; */
            "
            alt="insurance"
            slot="cover"
            :src="getPhoto()"
          />
        </div>
      </a>
      <div>
        <h4 class="value">{{ value }}</h4>
        <!-- <div class="icon" v-html="icon"></div> -->
      </div>
    </a-card>
  </router-link>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: "",
    },
    image: {
      type: String,
      default: "",
    },
    // suffix: {
    //   type: String,
    //   default: "",
    // },
    icon: {
      type: String,
      default: "",
    },
    link: {
      type: String,
      default: "",
    },
    // status: {
    //   type: String,
    //   default: "success",
    // },
  },
  data() {
    return {};
  },
  created() {
    // props are exposed on `this`
    //console.log(this.image);
  },
  methods: {
    getPhoto() {
      return this.image;
    },
  },
};
</script>

<style lang="scss" scoped>
.widget {
  // border-radius: 12px;
  transform: scale(1);
  transition: transform 0.3s ease;
  // border: solid 3px #008000;
  // background: orange;
  // text-align: center;

  .ant-card-body {
    padding: 16px;
    position: relative;
  }
  .value {
    margin: 5px 5px 0 5px;
    //color: aliceblue;
    // max-width: fit-content;
    text-align: center;

    // font-weight: bold;
    // font-size: 30px;
  }
  .icon {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 95px;
    bottom: 0;
    right: 16px;
    margin-left: 10px;
    //background-color: #fff;
    border-radius: 30px;
    box-shadow: 0.5px 4px 6px rgba(0, 0, 0.12, 0.12);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .icon svg path {
    fill: #faeb64;
  }
}
.widget:hover {
  //background: orange;
  // background: #008000;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
  text-align: center;
  transform: scale(1.05);
}
</style>
